import { Fab } from '@mui/material'
import { FC, useState } from 'react'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import { ChatBubble } from './chat/ChatBubble'
import { ChannelProvider } from 'ably/react'

type ChatFABProps = {
    poolCode: string
}

export const ChatFAB: FC<ChatFABProps> = (props) => {
    const { poolCode } = props
    const [showChat, setShowChat] = useState(false)

    return (
        <>
            {!showChat && (
                <Fab
                    onClick={() => setShowChat(true)}
                    sx={{ position: 'fixed', right: 20, bottom: 20 }}
                    color="secondary"
                    aria-label="chat"
                >
                    <ChatBubbleIcon />
                </Fab>
            )}
            {showChat && (
                <ChannelProvider channelName={`footory-chat-${poolCode}`}>
                    <ChatBubble onClose={() => setShowChat(false)} poolCode={poolCode} />
                </ChannelProvider>
            )}
        </>
    )
}
