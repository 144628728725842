import { FunctionComponent, ReactNode, useState } from 'react'

import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import React from 'react'
import Stack from '@mui/material/Stack'
import MuiButton from '@mui/material/Button'
import FacebookIcon from '@mui/icons-material/Facebook'
import { signIn } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { GoogleColorIcon } from './GoogleColorIcon'
import AppleIcon from '@mui/icons-material/Apple'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Chip,
    Divider,
    TextField,
    Typography,
    lighten,
    useTheme,
} from '@mui/material'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import { useForm } from 'react-hook-form'
import { useEmbeddedBrowser } from '@/hooks/useEmbeddedBrowser'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { GradientButton } from './styled/Buttons'

type EmailLoginInputs = {
    email: string
}

export const ConnectionDialog: FunctionComponent<{
    renderConnectionButton?: (callback: any) => ReactNode
    startOpen?: boolean
}> = ({ renderConnectionButton, startOpen = false }) => {
    const [connectionDialogOpen, setConnectionDialogOpen] = useState(startOpen)
    const { t } = useTranslation('common')
    const { register, formState, handleSubmit } = useForm<EmailLoginInputs>({
        defaultValues: { email: '' },
    })
    const isEmbeddedBrowser = useEmbeddedBrowser()
    const theme = useTheme()

    const handleCloseConnectionDialog = () => {
        setConnectionDialogOpen(false)
    }

    const action =
        renderConnectionButton ??
        ((callback) => (
            <GradientButton color="poolEventHeaderPrimary" onClick={callback} size="small">
                {t('connection.connectionButtonLabel')}
            </GradientButton>
        ))

    return (
        <>
            {action(() => setConnectionDialogOpen(true))}
            <Dialog onClose={handleCloseConnectionDialog} open={connectionDialogOpen}>
                <DialogTitle>{t('connection.header')}</DialogTitle>
                <DialogContent>
                    <Typography>{t('connection.signIn.text')}</Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '25px',
                            gap: '10px',
                            alignItems: 'center',
                        }}
                    >
                        <Chip
                            variant="outlined"
                            sx={{
                                height: 'auto',
                                borderRadius: '20px',
                                paddingX: '10px',
                                borderColor: '#000',
                                fontWeight: '600',
                                paddingY: '5px',
                            }}
                            icon={
                                <FacebookIcon
                                    style={{
                                        color: '#4267B2',
                                        fontSize: 40,
                                    }}
                                    sx={{
                                        verticalAlign: 'middle',
                                    }}
                                />
                            }
                            onClick={() => signIn('facebook')}
                            label={t('connection.facebookButtonLabel')}
                        />
                        <Chip
                            variant="outlined"
                            sx={{
                                height: 'auto',
                                borderRadius: '20px',
                                paddingX: '10px',
                                borderColor: '#000',
                                fontWeight: '600',
                            }}
                            disabled={isEmbeddedBrowser}
                            icon={
                                <GoogleColorIcon
                                    style={{ fontSize: 40 }}
                                    sx={{
                                        marginTop: '7px',
                                    }}
                                />
                            }
                            onClick={() => signIn('google')}
                            label={t('connection.googleButtonLabel')}
                        />
                        {isEmbeddedBrowser && (
                            <>
                                <Accordion
                                    variant="outlined"
                                    sx={{
                                        bgcolor: lighten(theme.palette.info.light, 0.75),
                                        border: 'none',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ArrowDownwardIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography>
                                            {t('labelGoogleLoginDisabled', {
                                                ns: 'pools',
                                            })}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            {t('labelGoogleLoginDisabledDetails', {
                                                ns: 'pools',
                                            })}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        )}
                        <Chip
                            variant="outlined"
                            sx={{
                                height: 'auto',
                                borderRadius: '20px',
                                paddingX: '10px',
                                borderColor: '#000',
                                fontWeight: '600',
                                paddingY: '2px',
                            }}
                            icon={
                                <AppleIcon
                                    style={{ fontSize: 40 }}
                                    sx={{
                                        marginBottom: '5px',
                                    }}
                                />
                            }
                            onClick={() => signIn('apple')}
                            label={t('connection.appleButtonLabel')}
                        />
                        <Divider />
                        <Typography textAlign="center">
                            Ou recevez un lien de connexion par courriel
                        </Typography>
                        <form
                            onSubmit={handleSubmit(async (data) => {
                                signIn('email', {
                                    email: data.email,
                                })
                            })}
                            style={{ width: '100%', textAlign: 'center' }}
                        >
                            <Stack spacing={3} direction="column">
                                <TextField
                                    {...register('email', {
                                        required: true,
                                        pattern: {
                                            value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                            message: 'Invalid email address',
                                        },
                                    })}
                                    size="small"
                                    placeholder="Entrez votre courriel"
                                    type="email"
                                />

                                <Chip
                                    variant="outlined"
                                    sx={{
                                        height: 'auto',
                                        borderRadius: '30px',
                                    }}
                                    icon={
                                        <MailOutlineIcon
                                            style={{ fontSize: 40 }}
                                            sx={{
                                                verticalAlign: 'middle',
                                            }}
                                        />
                                    }
                                    disabled={!formState.isValid || formState.isSubmitting}
                                    clickable
                                    component="button"
                                    type="submit"
                                    label={t('connection.emailButtonLabel')}
                                />
                            </Stack>
                        </form>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}
