import { Box } from '@mui/material'
import { FC } from 'react'
import { ChatMessage, ChatMessageInfo, currentUserStyle, otherUserStyle } from './ChatMessage'
import { useUserSession } from '@/hooks/useUserSession'
import { PublicUser } from '@/utils/pool_types'
import { ChatMessageTime } from './ChatMessageTime'

export type PoolChatMoment = { minute: Date; user: PublicUser; messages: ChatMessageInfo[] }

type ChatMessageContainerProps = {
    // moments represent a bunch of CONSECUTIVE message sent in the same minute by the same user
    moments: PoolChatMoment[]
}

export const ChatMessageContainer: FC<ChatMessageContainerProps> = (props) => {
    const { moments } = props
    const [session] = useUserSession()

    return (
        <Box
            sx={{
                flex: 1,
                overflowY: 'scroll',
                display: 'flex',
                flexDirection: 'column-reverse',
            }}
        >
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                    alignItems: 'flex-start',
                }}
            >
                {moments.map((m) => (
                    <>
                        {m.messages.map((msg) => (
                            <ChatMessage
                                message={{
                                    id: msg.id,
                                    message: msg.message,
                                }}
                                sx={
                                    m.user.id === session?.user?.userXRefId
                                        ? currentUserStyle
                                        : otherUserStyle
                                }
                            />
                        ))}
                        <ChatMessageTime
                            user={m.user}
                            date={m.minute}
                            align={m.user.id === session?.user?.userXRefId ? 'right' : 'left'}
                        />
                    </>
                ))}
            </Box>
        </Box>
    )
}
