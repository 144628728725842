import {
    Box,
    Button,
    Container,
    CssBaseline,
    Drawer,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ThemeProvider,
    Typography,
    useTheme,
} from '@mui/material'
import { FC, ReactElement, useMemo, useState } from 'react'
import { CurrentUser } from '../CurrentUser'
import NextLink from 'next/link'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useTranslation } from 'next-i18next'
import { Footer } from '@/PageLayout'
import { setCookie } from '@/utils/cookies'
import { useRouter } from 'next/router'
import { Logo } from './Logo'
import { getDefaultPoolEventTheme, getPoolEventThemeData } from '@/utils/pools_themes'
import Head from 'next/head'
import { useReactNativeWebView } from '@/hooks/useReactNativeWebView'
import { PoolEventResponse, PoolResponse } from '@/utils/pool_types'
import { LINEAR_GRADIENT } from '@/utils/poolStyles'
import { Footory } from './Footory'
import { getCookieConsentValue } from 'react-cookie-consent'
import MenuIcon from '@mui/icons-material/Menu'
import { SupportFAB } from '../SupportFAB'
import { GradientButton } from '../styled/Buttons'
import { useUserSession } from '@/hooks/useUserSession'
import { ChatFAB } from '../ChatFAB'
import * as Ably from 'ably'
import { AblyProvider, ChannelProvider } from 'ably/react'

export function getPoolPageLayout(page: ReactElement) {
    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>{page}</LocalizationProvider>
        </>
    )
}

type PoolLayoutProps = {
    children: JSX.Element
    pool?: PoolResponse
    poolEvent?: PoolEventResponse
    showLogo?: boolean
    showSupportFab?: boolean
    showMobileTopMenu?: boolean
    showFooter?: boolean
    secondaryLogo?: string
}

export const PoolLayout: FC<PoolLayoutProps> = (props) => {
    const theme = useTheme()
    const { pool, poolEvent } = props

    const poolEventThemeData = getPoolEventThemeData(pool?.event || poolEvent) ?? {
        headerBackgroundColor: '#fff',
        headerLogoColor: '#000',
        headerTextColor: '#000',
        primaryMainColor: '#049b4a',
        headerPrimaryMainColor: '#049b4a',
        primaryGradient: LINEAR_GRADIENT,
    }

    const poolTheme = getDefaultPoolEventTheme(theme, poolEventThemeData)

    const client = global.window
    const cookieConsent = client && getCookieConsentValue()

    return (
        <>
            <Head>
                <meta property="og:site_name" content="Footory Pools" />
                <meta
                    property="og:image"
                    content={`${process.env.NEXT_PUBLIC_BASE_URL}${
                        poolTheme.ogImage ?? '/images/og-image-1200x630.jpg'
                    }`}
                />
                {client && cookieConsent === 'true' && (
                    <script>
                        {window.gtag('consent', 'update', {
                            ad_storage: 'granted',
                            ad_user_data: 'granted',
                            ad_personalization: 'granted',
                            analytics_storage: 'granted',
                        })}
                    </script>
                )}
            </Head>
            <ThemeProvider theme={poolTheme.theme}>
                <CssBaseline />
                <PoolLayoutNoThemeWrapper {...props} secondaryLogo={poolTheme.secondaryLogo} />
            </ThemeProvider>
        </>
    )
}

export const PoolLayoutNoThemeWrapper: FC<PoolLayoutProps> = ({
    children,
    secondaryLogo: showSecondaryLogo,
    showFooter = true,
    showLogo = true,
    showSupportFab = true,
    showMobileTopMenu = true,
    pool,
}) => {
    const theme = useTheme()
    const { t } = useTranslation('pools')
    const router = useRouter()
    const isReactNativeWebView = useReactNativeWebView()
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [session] = useUserSession()

    const client = useMemo(
        () =>
            // Connect to Ably using the AblyProvider component and your API key
            new Ably.Realtime({
                key: process.env.NEXT_PUBLIC_ABLY_API_KEY,
                autoConnect: typeof window !== 'undefined',
            }),
        []
    )

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return
        }

        setDrawerOpen(open)
    }

    return (
        <AblyProvider client={client}>
            <Box bgcolor={theme.palette.background.poolEventHeader}>
                {!isReactNativeWebView && (
                    <Container>
                        <Box display="flex" gap="10px" flexDirection="row" alignItems="center">
                            <Box>
                                <NextLink href="/pools" passHref>
                                    <Link>
                                        <Box width={32} height={32}>
                                            <Logo logoColor={theme.palette.common.poolEventHeaderLogo} />
                                        </Box>
                                    </Link>
                                </NextLink>
                            </Box>
                            <Box
                                sx={{
                                    display: { xs: 'none', md: 'block' },
                                }}
                                width={90}
                            >
                                <Footory logoColor={theme.palette.common.poolEventHeaderLogo} />
                            </Box>
                            <CurrentUser />
                            {showMobileTopMenu && (
                                <Box
                                    justifyContent="flex-start"
                                    sx={{
                                        display: {
                                            xs: 'flex',
                                            md: 'none',
                                            marginLeft: 'auto',
                                        },
                                    }}
                                    display="flex"
                                    gap="25px"
                                    alignItems="center"
                                >
                                    <IconButton onClick={toggleDrawer(true)}>
                                        <MenuIcon
                                            color="poolEventHeaderPrimary"
                                            style={{ fontSize: '1.2em' }}
                                        />
                                    </IconButton>
                                </Box>
                            )}
                            <Box
                                display="flex"
                                gap="10px"
                                flexDirection="row"
                                alignItems="center"
                                sx={{ display: { xs: 'none', md: 'flex' } }}
                            >
                                {session && (
                                    <NextLink passHref href="/pools/me">
                                        <GradientButton
                                            variant="contained"
                                            sx={{ fontSize: '14px' }}
                                            color="poolEventHeaderPrimary"
                                        >
                                            {t('buttonMyPools')}
                                        </GradientButton>
                                    </NextLink>
                                )}
                                <Typography color={theme.palette.poolEventHeaderPrimary?.main}>
                                    &#x2022;
                                </Typography>
                                <NextLink passHref href="/pools/learn-more">
                                    <Button color="poolEventHeaderPrimary">{t('howItWorks')}</Button>
                                </NextLink>
                                <Typography color={theme.palette.poolEventHeaderPrimary?.main}>
                                    &#x2022;
                                </Typography>
                                {/* locales */}
                                <Button
                                    color="poolEventHeaderPrimary"
                                    key={router.locale === 'en' ? 'fr' : 'en'}
                                    sx={{ my: 2, display: 'block' }}
                                    onClick={() => {
                                        const locale = router.locale === 'en' ? 'fr' : 'en'
                                        setCookie('NEXT_LOCALE', locale, 30)
                                        router.push(router.asPath, undefined, {
                                            locale,
                                        })
                                    }}
                                >
                                    {router.locale === 'en' ? 'français' : 'english'}
                                </Button>
                            </Box>
                        </Box>
                    </Container>
                )}
            </Box>
            {children}
            {showFooter && (
                <Box marginTop="100px" paddingBottom="50px" paddingTop="50px" bgcolor="#111" color="#fff">
                    {!isReactNativeWebView && <Footer />}
                    <Container sx={{ marginY: '30px' }}>
                        <Box textAlign="center">{t('madeWithLove')}</Box>
                    </Container>
                    <Container sx={{ marginTop: '30px' }}>
                        <Box textAlign="center">
                            <Link
                                fontSize="12px"
                                color="secondary"
                                href="https://www.flaticon.com/authors/guillem-rodriguez"
                                title="champions icons"
                            >
                                {t('iconsAttribution')}
                            </Link>
                        </Box>
                    </Container>
                </Box>
            )}
            {showMobileTopMenu && (
                <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                    <Box
                        sx={{
                            width: 250,
                        }}
                        role="presentation"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                    >
                        <List>
                            <ListItem disablePadding>
                                <NextLink href="/pools/me" passHref>
                                    <ListItemButton>
                                        <ListItemText primary={t('buttonMyPools')} />
                                    </ListItemButton>
                                </NextLink>
                            </ListItem>
                            <ListItem disablePadding>
                                <NextLink href="/pools/learn-more" passHref>
                                    <ListItemButton>
                                        <ListItemText primary={t('howItWorks')} />
                                    </ListItemButton>
                                </NextLink>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton
                                    onClick={() => {
                                        const locale = router.locale === 'en' ? 'fr' : 'en'
                                        setCookie('NEXT_LOCALE', locale, 30)
                                        router.push(router.asPath, undefined, {
                                            locale,
                                        })
                                    }}
                                >
                                    <ListItemText primary={router.locale === 'en' ? 'Français' : 'English'} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Box>
                </Drawer>
            )}
            {/* {showSupportFab && <SupportFAB />} */}
            {pool && <ChatFAB poolCode={pool.code} />}
        </AblyProvider>
    )
}
