export class ApiError extends Error {
    status: number

    constructor(message: string, status: number) {
        super(message)
        this.status = status
    }
}

export const SYSTEM_TOKEN = Buffer.from(
    `${process.env.FOOTORY_SYSTEM_API_USERNAME}:${process.env.FOOTORY_SYSTEM_API_PASSWORD}`,
    'utf8'
).toString('base64')

type RequestOptions = {
    token?: string
    systemToken?: string
    headersOverrides?: Record<string, string>
}

class ApiHelper {
    // this is to ensure we do not have two simultaneous calls to refresh_token since refresh_token is sent
    // in http cookies, so first call would revoke refresh_token used in second call...
    //getFreshTokenPromise: Promise<any> | null = null;

    constructor(private host: string) {}

    async get(url: string, token?: string): Promise<any>
    async get(url: string, options: RequestOptions): Promise<any>
    async get(url: string, options?: string | RequestOptions): Promise<any> {
        const parsedOptions =
            typeof options === 'string'
                ? {
                      token: options,
                  }
                : options

        const headers = new Headers()
        const { token, headersOverrides, systemToken } = parsedOptions ?? {}

        if (headersOverrides) {
            for (const key in headersOverrides) {
                headers.set(key, headersOverrides[key])
            }
        }

        if (token) {
            // await this.ensureTokenIsFresh();
            const bearer = `Bearer ${token}`
            headers.append('Authorization', bearer)
        } else if (systemToken) {
            const basic = `Basic ${systemToken}`
            headers.append('Authorization', basic)
        }

        const res = await fetch(`${this.host}${url}`, {
            method: 'GET',
            headers: headers,
            credentials: 'include',
            mode: 'cors',
        })

        if (!res.ok) {
            throw new ApiError(`${res.status} ${res.statusText} GET ${this.host}${url}`, res.status)
        }

        return this.parseResponse(res)
    }

    async parseResponse(res: Response): Promise<any> {
        const contentType = res.headers.get('content-type')
        const contentLength = res.headers.get('content-length')

        if (contentLength != null) {
            if (Number.parseInt(contentLength) === 0) {
                return
            }
        }

        if (contentType && contentType.indexOf('application/json') !== -1) {
            return res.json()
        } else {
            const text = await res.text()
            return text
        }
    }

    async delete(url: string, body: any, token?: string): Promise<any>
    async delete(url: string, body: any, options: RequestOptions): Promise<any>
    async delete(url: string, body: any, options?: string | RequestOptions): Promise<any> {
        const parsedOptions =
            typeof options === 'string'
                ? {
                      token: options,
                  }
                : options

        return this.sendWithBody(url, 'DELETE', body, parsedOptions ?? {})
    }

    async post(url: string, body: any, token?: string): Promise<any>
    async post(url: string, body: any, options: RequestOptions): Promise<any>
    async post(url: string, body: any, options?: string | RequestOptions): Promise<any> {
        const parsedOptions =
            typeof options === 'string'
                ? {
                      token: options,
                  }
                : options

        return this.sendWithBody(url, 'POST', body, parsedOptions ?? {})
    }

    async put(url: string, body: any, token?: string): Promise<any>
    async put(url: string, body: any, options: RequestOptions): Promise<any>
    async put(url: string, body: any, options?: string | RequestOptions): Promise<any> {
        const parsedOptions =
            typeof options === 'string'
                ? {
                      token: options,
                  }
                : options

        return this.sendWithBody(url, 'PUT', body, parsedOptions ?? {})
    }

    async patch(url: string, body: any, token?: string): Promise<any>
    async patch(url: string, body: any, options: RequestOptions): Promise<any>
    async patch(url: string, body: any, options?: string | RequestOptions): Promise<any> {
        const parsedOptions =
            typeof options === 'string'
                ? {
                      token: options,
                  }
                : options

        return this.sendWithBody(url, 'PATCH', body, parsedOptions ?? {})
    }

    async sendWithBody(
        url: string,
        method: 'POST' | 'DELETE' | 'PUT' | 'PATCH',
        body: any,
        options: RequestOptions
    ) {
        const { token, headersOverrides, systemToken } = options
        const headers = new Headers()
        headers.append('Content-Type', 'application/json;charset=UTF-8')

        if (headersOverrides) {
            for (const key in headersOverrides) {
                headers.set(key, headersOverrides[key])
            }
        }

        if (token) {
            const bearer = `Bearer ${token}`
            headers.append('Authorization', bearer)
        } else if (systemToken) {
            const basic = `Basic ${systemToken}`
            headers.append('Authorization', basic)
        }

        const res = await fetch(`${this.host}${url}`, {
            body: typeof body === 'string' ? body : JSON.stringify(body),
            method,
            headers: headers,
            credentials: 'include',
            mode: 'cors',
        })

        if (!res.ok) {
            throw new ApiError(`${res.status} ${res.statusText} ${method} ${this.host}${url}`, res.status)
        }

        if (res.status === 204) return // 204 = no content

        return this.parseResponse(res)
    }
}

const api = ''
export const apiHelper = new ApiHelper(api)
