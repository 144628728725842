import { Theme, createTheme } from '@mui/material'
import { PoolEventResponse } from './pool_types'

export type PoolEventTheme = {
    theme: Theme
    secondaryLogo?: string
    ogImage?: string
}

export type PoolEventThemeData = {
    headerBackgroundColor: string
    headerTextColor: string
    headerLogoColor: string
    primaryMainColor: string
    headerPrimaryMainColor: string
    primaryGradient?: string
}

export function getPoolEventThemeData(
    poolEvent?: PoolEventResponse
): PoolEventThemeData | undefined {
    if (!poolEvent) {
        return undefined
    }

    const {
        headerBackgroundColor,
        headerLogoColor,
        headerPrimaryMainColor,
        headerTextColor,
        primaryMainColor,
    } = poolEvent

    return headerBackgroundColor &&
        headerLogoColor &&
        headerPrimaryMainColor &&
        headerTextColor &&
        primaryMainColor
        ? {
              headerBackgroundColor,
              headerLogoColor,
              headerPrimaryMainColor,
              headerTextColor,
              primaryMainColor,
          }
        : undefined
}

export function getDefaultPoolEventTheme(
    theme: Theme,
    poolEventThemeData: PoolEventThemeData
): PoolEventTheme {
    return {
        theme: createTheme({
            ...theme,
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: ({ ownerState, theme }) => {
                            if (ownerState.variant !== 'text') {
                                return {
                                    boxShadow: 'none',
                                    fontSize: '1.2rem',
                                    '&:hover': {
                                        boxShadow: 'none',
                                    },
                                }
                            } else {
                                return {
                                    fontSize: '1rem',
                                }
                            }
                        },
                    },
                },
            },
            palette: {
                ...theme.palette,
                background: {
                    poolEventHeader: poolEventThemeData.headerBackgroundColor,
                },
                text: {
                    poolEventHeader: poolEventThemeData.headerTextColor,
                },
                common: {
                    poolEventHeaderLogo: poolEventThemeData.headerLogoColor,
                },
                primary: theme.palette.augmentColor({
                    color: {
                        main: poolEventThemeData.primaryMainColor,
                    },
                    name: 'footory',
                }),
                poolEventHeaderPrimary: theme.palette.augmentColor({
                    color: {
                        main: poolEventThemeData.headerPrimaryMainColor,
                    },
                }),
                primaryGradient: poolEventThemeData.primaryGradient,
                poolEventHeader: theme.palette.augmentColor({
                    color: { main: poolEventThemeData.headerBackgroundColor },
                }),
            },
        }),
    }
}
