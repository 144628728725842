import { PublicUser } from '@/utils/pool_types'
import { getDateFnsLocaleFromNextjsLocale } from '@/utils/time'
import { Avatar, Box } from '@mui/material'
import { formatRelative } from 'date-fns'
import { useRouter } from 'next/router'
import { FC } from 'react'

type ChatMessageTimeProps = {
    date: Date
    user: PublicUser
    align: 'left' | 'right'
}

export const ChatMessageTime: FC<ChatMessageTimeProps> = (props) => {
    const { date, user, align } = props
    const { locale } = useRouter()

    return (
        <Box
            sx={{
                fontSize: '12px',
                color: '#666',
                alignSelf: align === 'right' ? 'flex-end' : 'flex-start',
                display: 'flex',
                flexDirection: align === 'right' ? 'row' : 'row-reverse',
                alignItems: 'center',
                gap: '5px',
            }}
        >
            <span>
                {formatRelative(date, new Date(), { locale: getDateFnsLocaleFromNextjsLocale(locale) })}
            </span>
            <Avatar srcSet={user.imageUrl} sx={{ width: 24, height: 24 }} alt={user.name} title={user.name} />
        </Box>
    )
}
