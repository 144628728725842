import { User } from '@/types'
import { Avatar } from '@mui/material'
import { FC } from 'react'

export const getAvatarAbbreviation = (
    displayName: string
): string | undefined => {
    return displayName && displayName[0]
}

export const UserAvatar: FC<{
    user: Pick<User, 'displayName' | 'picture'>
}> = ({ user }) => {
    return user.picture ? (
        <Avatar sx={{ width: 32, height: 32 }}>
            <img
                alt={user.displayName}
                referrerPolicy="no-referrer"
                width={32}
                src={user.picture}
            />{' '}
        </Avatar>
    ) : (
        <Avatar sx={{ width: 32, height: 32 }}>
            {getAvatarAbbreviation(user.displayName ?? '')}
        </Avatar>
    )
}
