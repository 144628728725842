import { Box, IconButton } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { ChatMessageContainer, PoolChatMoment } from './ChatMessageContainer'
import { useUserSession } from '@/hooks/useUserSession'
import { apiHelper } from '@/apiHelper'
import { add, isSameMinute, parseISO } from 'date-fns'
import { PoolChatMessage } from '@/utils/pool_types'
import { ChatMessageInput } from './ChatMessageInput'
import { useChannel, useConnectionStateListener } from 'ably/react'
import CloseIcon from '@mui/icons-material/Close'

type ChatBubbleProps = {
    onClose: () => void
    poolCode: string
}

export const ChatBubble: FC<ChatBubbleProps> = (props) => {
    const { onClose, poolCode } = props
    const [session] = useUserSession()
    const [moments, setMoments] = useState<PoolChatMoment[]>([])

    useConnectionStateListener('connected', () => {
        console.log('Connected to sockets!')
    })

    // subscribe to websocket to get new messages
    useChannel(`footory-chat-${poolCode}`, 'chat-message-added', (message) => {
        const newChatMessage = message.data as PoolChatMessage
        let added = false

        for (const moment of moments) {
            if (
                isSameMinute(moment.minute, parseISO(newChatMessage.date)) &&
                moment.user.id === newChatMessage.user.id
            ) {
                moment.messages.push({
                    id: newChatMessage.id,
                    message: newChatMessage.message,
                })
                added = true
                break
            }
        }

        if (!added) {
            moments.push({
                messages: [
                    {
                        id: newChatMessage.id,
                        message: newChatMessage.message,
                    },
                ],
                minute: parseISO(newChatMessage.date),
                user: newChatMessage.user,
            })
        }

        setMoments([...moments])
    })

    useEffect(() => {
        async function loadChatMessages(poolCode: string): Promise<void> {
            const messages: PoolChatMessage[] = await apiHelper.get(
                `/api/pools/c/${poolCode}/chat?from=${encodeURIComponent(
                    add(new Date(), {
                        days: -1,
                    }).toISOString()
                )}&to=${encodeURIComponent(new Date().toISOString())}`,
                session?.accessToken
            )

            messages.sort((a, b) => parseISO(a.date).getTime() - parseISO(b.date).getTime())

            const moments: PoolChatMoment[] = []

            for (const currentMessage of messages) {
                if (moments.length === 0) {
                    moments.push({
                        messages: [{ id: currentMessage.id, message: currentMessage.message }],
                        minute: parseISO(currentMessage.date),
                        user: currentMessage.user,
                    })
                } else {
                    const previousMoment = moments[moments.length - 1]

                    if (
                        isSameMinute(previousMoment.minute, parseISO(currentMessage.date)) &&
                        previousMoment.user.id === currentMessage.user.id
                    ) {
                        previousMoment.messages.push({
                            id: currentMessage.id,
                            message: currentMessage.message,
                        })
                    } else {
                        moments.push({
                            messages: [{ id: currentMessage.id, message: currentMessage.message }],
                            minute: parseISO(currentMessage.date),
                            user: currentMessage.user,
                        })
                    }
                }
            }

            setMoments(moments)
        }

        loadChatMessages(poolCode)
    }, [poolCode, session?.accessToken])

    const postMessage = async (message: string): Promise<void> => {
        await apiHelper.post(`/api/pools/c/${poolCode}/chat`, { message }, session?.accessToken)
    }

    return (
        <Box
            sx={{
                position: 'fixed',
                width: '330px',
                height: '75%',
                right: 20,
                bottom: 20,
                backgroundColor: '#eee',
                borderRadius: '10px',
                padding: '5px',
                overflowY: 'scroll',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <ChatMessageContainer moments={moments} />
            <ChatMessageInput onSubmit={(message) => postMessage(message)} />
        </Box>
    )
}
