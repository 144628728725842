import { useEffect, useState } from 'react'

export const useEmbeddedBrowser = () => {
    const [isEmbeddedBrowser, setIsEmbeddedBrowser] = useState(false)

    useEffect(() => {
        const isEmbeddedBrowser =
            /FBAN|FBAV|FBMS|FB_IAB|FB4A|FBAN\/Messenger/.test(
                window.navigator.userAgent
            )
        if (isEmbeddedBrowser) {
            setIsEmbeddedBrowser(true)
        }
    }, [])

    return isEmbeddedBrowser
}
