import { format, formatDistance, isSameDay } from 'date-fns'
import { frCA, enCA } from 'date-fns/locale'

export interface FormatScheduleItemTimesResult {
    formattedStartAt: string
    formattedEndAt: string | undefined
    formattedArriveAt: string | undefined
    distanceToNow: string | undefined
}

export function getIntlLocalesArgumentNextjsLocale(locale?: string) {
    return locale?.toLowerCase() === 'fr' ? 'fr-CA' : 'en-CA'
}

export function getDateFnsLocaleFromNextjsLocale(locale?: string) {
    return locale?.toLowerCase() === 'fr' ? frCA : enCA
}

export function formatScheduleItemTimes(
    startAt: string,
    endAt?: string,
    arriveAt?: string,
    locale?: string
): FormatScheduleItemTimesResult {
    const startAtDate = new Date(startAt)
    const endAtDate = endAt && new Date(endAt)
    const arriveAtDate = arriveAt && new Date(arriveAt)

    const endAtFormat =
        endAtDate && isSameDay(startAtDate, endAtDate) ? 'p' : 'PP p'

    const arriveAtFormat =
        arriveAtDate && isSameDay(startAtDate, arriveAtDate) ? 'p' : 'PP p'

    const now = Date.now()

    const libLocale = getDateFnsLocaleFromNextjsLocale(locale)

    const distanceToNow =
        isSameDay(startAtDate, now) && now <= startAtDate.getTime()
            ? formatDistance(startAtDate, now, { locale: libLocale })
            : undefined

    const formattedStartAt = format(startAtDate, 'PP p', { locale: libLocale })
    const formattedEndAt =
        endAtDate && format(endAtDate, endAtFormat, { locale: libLocale })
    const formattedArriveAt =
        arriveAtDate &&
        format(arriveAtDate, arriveAtFormat, { locale: libLocale })

    return {
        formattedStartAt,
        formattedEndAt,
        formattedArriveAt,
        distanceToNow,
    }
}

export function getMinuteStringFormat(date: Date) {
    if (date.getMinutes() === 0) {
        return ''
    } else {
        return `'h'mm `
    }
}

export function getTimeStringFormat(date: Date) {
    if (date.getHours() === 0) {
        return ''
    } else {
        return `, h${getMinuteStringFormat(date)}aaa`
    }
}

export function getFormattedDateFormat(date: Date): string {
    return getFormattedDateRangeEndFormat(date, date)
}

export function getFormattedDateRangeEndFormat(start: Date, end: Date): string {
    return `PP${getTimeStringFormat(end)}`
}

export function getFormattedDateOnlyRangeEndFormat(
    start: Date,
    end: Date
): string {
    return `PP`
}

export function getFormattedDateRangeStartFormat(
    start: Date,
    end: Date
): string {
    if (start.getFullYear() === end.getFullYear()) {
        return `'toLocaleDateStringDayMonth'${getTimeStringFormat(start)}`
    } else {
        return `PP${getTimeStringFormat(start)}`
    }
}

export function getFormattedDateOnlyRangeStartFormat(
    start: Date,
    end: Date
): string {
    if (start.getFullYear() === end.getFullYear()) {
        return `'toLocaleDateStringDayMonth'`
    } else {
        return `PP`
    }
}
