import { Box, SxProps, Theme } from '@mui/material'
import { FC } from 'react'

export const currentUserStyle: SxProps<Theme> = {
    backgroundColor: (theme) => theme.palette.primary.main,
    color: (theme) => theme.palette.primary.contrastText,
    alignSelf: 'flex-end',
    marginLeft: '20px',
}

export const otherUserStyle: SxProps<Theme> = { backgroundColor: '#ddd', alignItems: 'flex-start' }

export type ChatMessageInfo = {
    id: string
    message: string
}

type ChatMessageProps = {
    message: ChatMessageInfo
    sx: SxProps<Theme> | undefined
}

export const ChatMessage: FC<ChatMessageProps> = (props) => {
    const { message, sx } = props

    return (
        <Box
            sx={{
                borderRadius: '10px',
                paddingX: '7px',
                paddingY: '3px',
                fontSize: '14px',
                ...sx,
            }}
        >
            {message.message}
        </Box>
    )
}
