import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material'
import { useTranslation } from 'next-i18next'
import React, { FunctionComponent, useContext, useState } from 'react'
import { ConfirmOptions } from '../utils/confirmDeleteOptions'

export interface ConfirmModalContextProps {
    setConfirmOptions: (options: ConfirmOptions | undefined) => void
    working: boolean
}

const ConfirmModalContext = React.createContext<ConfirmModalContextProps>({
    setConfirmOptions: () => undefined,
    working: false,
})

export const useConfirmModal = () => useContext(ConfirmModalContext)

export const ConfirmModalContextProvider: FunctionComponent<{
    children: React.ReactNode
}> = ({ children }) => {
    const [confirmOptions, setConfirmOptions] = useState<
        ConfirmOptions | undefined
    >(undefined)
    const [working, setWorking] = useState(false)
    const { t } = useTranslation('common')

    return (
        <ConfirmModalContext.Provider
            value={{
                setConfirmOptions: (options?) => setConfirmOptions(options),
                working,
            }}
        >
            <Dialog
                onClose={() => setConfirmOptions(undefined)}
                open={!!confirmOptions}
            >
                <DialogTitle>{confirmOptions?.title ?? ''}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {confirmOptions?.text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="text"
                        disabled={working}
                        onClick={() => {
                            try {
                                confirmOptions?.negativeCallback &&
                                    confirmOptions?.negativeCallback()
                            } finally {
                                setConfirmOptions(undefined)
                            }
                        }}
                    >
                        {confirmOptions?.negativeButtonText ??
                            t('confirmation.no')}
                    </Button>
                    <Button
                        variant="text"
                        disabled={working}
                        onClick={async () => {
                            setWorking(true)
                            try {
                                await confirmOptions?.positiveCallback(
                                    confirmOptions
                                )
                            } finally {
                                setWorking(false)
                                setConfirmOptions(undefined)
                            }
                        }}
                    >
                        {confirmOptions?.positiveButtonText ??
                            t('confirmation.yes')}{' '}
                        {working && <CircularProgress size={16} />}
                    </Button>
                </DialogActions>
            </Dialog>
            {children}
        </ConfirmModalContext.Provider>
    )
}
