import * as React from 'react'
export const Logo: React.FC<
    React.SVGProps<SVGSVGElement> & { logoColor: string }
> = ({ logoColor = '#000', ...props }) => {
    return (
        <svg
            fill={logoColor}
            viewBox="0.303 0.028 245.53 245.53"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M 243.155 123 C 243.155 155.354 229.074 186.851 207.962 207.962 C 186.851 229.074 155.354 243.155 123 243.155 C 115.199 243.155 107.448 242.336 99.88 240.798 L 106.581 218.88 C 111.852 219.724 117.324 220.155 123 220.155 C 150.655 220.155 173.485 209.912 191.699 191.699 C 209.912 173.485 220.155 150.655 220.155 123 C 220.155 95.345 209.912 72.515 191.699 54.301 C 183.168 45.771 173.624 38.988 163.047 34.188 L 169.673 12.514 C 184.24 18.674 197.401 27.476 207.962 38.038 C 229.074 59.149 243.155 90.646 243.155 123 Z M 38.038 207.962 C 16.926 186.851 2.845 155.354 2.845 123 C 2.845 90.646 16.926 59.149 38.038 38.038 C 59.149 16.926 90.646 2.845 123 2.845 C 130.348 2.845 137.653 3.571 144.801 4.942 L 138.083 26.915 C 133.225 26.207 128.198 25.845 123 25.845 C 95.345 25.845 72.515 36.088 54.301 54.301 C 36.088 72.515 25.845 95.345 25.845 123 C 25.845 150.655 36.088 173.485 54.301 191.699 C 62.501 199.899 71.637 206.483 81.728 211.245 L 75.09 232.955 C 61.015 226.825 48.3 218.224 38.038 207.962 Z"
                transform="matrix(1, 0, 0, 1, 0, 1.4210854715202004e-14)"
            />
            <path d="M 111.066 106.007 L 95.219 106.007 L 95.219 161.07 L 69.567 161.07 L 69.567 106.007 L 59.764 106.007 L 59.764 93.383 L 70.105 87.071 L 70.105 85.862 Q 70.105 71.492 76.014 64.911 Q 81.923 58.331 95.353 58.331 Q 101.128 58.331 105.828 59.203 Q 110.529 60.076 115.632 61.822 L 110.126 78.476 Q 108.246 77.938 105.896 77.535 Q 103.545 77.133 100.859 77.133 Q 95.219 77.133 95.219 84.788 L 95.219 86.802 L 111.066 86.802 Z M 112.137 86.802 L 139.131 86.802 L 151.756 129.375 Q 152.159 130.718 152.494 133.001 Q 152.83 135.284 152.964 137.433 L 153.502 137.433 Q 153.77 134.747 154.24 132.8 Q 154.71 130.853 155.113 129.51 L 168.006 86.802 L 194.194 86.802 L 164.783 165.771 Q 161.425 174.769 156.926 180.946 Q 152.427 187.124 145.846 190.213 Q 139.266 193.302 129.462 193.302 Q 125.97 193.302 123.418 192.966 Q 120.867 192.631 118.986 192.228 L 118.986 172.083 Q 120.464 172.351 122.545 172.62 Q 124.627 172.888 126.91 172.888 Q 133.222 172.888 136.244 169.195 Q 139.266 165.502 141.146 160.667 L 141.415 159.996 Z" />
        </svg>
    )
}
