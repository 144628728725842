import { useEffect, useState } from 'react'

export const useReactNativeWebView = () => {
    const [isReactNativeWebView, setIsReactNativeWebView] = useState(false)

    useEffect(() => {
        const isReactNativeWebView = /footory\/app/.test(
            window.navigator.userAgent
        )
        if (isReactNativeWebView) {
            setIsReactNativeWebView(true)
        }
    }, [])

    return isReactNativeWebView
}
