import { Box, IconButton, OutlinedInput } from '@mui/material'
import { FC, FormEventHandler, useState } from 'react'
import SendIcon from '@mui/icons-material/Send'

type ChatMessageInputProps = {
    onSubmit: (message: string) => Promise<void>
}

export const ChatMessageInput: FC<ChatMessageInputProps> = (props) => {
    const [message, setMessage] = useState('')
    const [working, setWorking] = useState(false)

    const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault()
        setWorking(true)
        try {
            await props.onSubmit(message)
            setMessage('')
        } catch (e) {
            console.error(e)
        } finally {
            setWorking(false)
        }
    }

    return (
        <form onSubmit={onSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
                <OutlinedInput
                    fullWidth
                    sx={{ fontSize: '14px', backgroundColor: '#fff' }}
                    inputProps={{ style: { padding: '5px' } }}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <IconButton disabled={!message || working} type="submit">
                    <SendIcon />
                </IconButton>
            </Box>
        </form>
    )
}
