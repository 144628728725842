import { ReactElement, useEffect } from 'react'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import React from 'react'
import PageLayout from '../PageLayout'
import { SessionProvider as AuthProvider } from 'next-auth/react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '/node_modules/flag-icons/css/flag-icons.min.css'
import './styles.css'
import { CacheProvider, EmotionCache } from '@emotion/react'
import {
    ThemeProvider,
    CssBaseline,
    createTheme,
    Container,
    PaletteColor,
    Button,
    PaletteColorOptions,
} from '@mui/material'
import type {} from '@mui/lab/themeAugmentation'
import * as ga from '../utils/ga'
import { ErrorBoundary } from 'react-error-boundary'
import '@fontsource-variable/raleway'
import createEmotionCache from '../utils/createEmotionCache'
import { responsiveFontSizes } from '@mui/material'
import NextNProgress from 'nextjs-progressbar'
import { useRouter } from 'next/router'
import { Trans, appWithTranslation, useTranslation } from 'next-i18next'
import { ConfirmModalContextProvider } from '../components/ConfirmModalContext'
import { PoolLayout } from '@/components/pools/PoolLayout'
import { GoogleAdSense } from '@/components/GoogleAdSense'
import CookieConsent from 'react-cookie-consent'
import CloseIcon from '@mui/icons-material/Close'
export { reportWebVitals } from 'next-axiom'

declare module '@mui/material/styles' {
    interface TypeBackground {
        poolEventHeader: string
    }

    interface TypeText {
        poolEventHeader: string
    }

    interface Palette {
        primaryGradient?: string
        poolEventHeaderPrimary?: PaletteColor
        poolEventHeader?: PaletteColor
    }

    interface CommonColors {
        poolEventHeaderLogo: string
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        primaryGradient?: string
        poolEventHeaderPrimary?: PaletteColorOptions
        poolEventHeader?: PaletteColorOptions
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        poolEventHeaderPrimary: true
        poolEventHeader: true
    }
}

declare module '@mui/material/SvgIcon' {
    interface SvgIconPropsColorOverrides {
        poolEventHeaderPrimary: true
        poolEventHeader: true
    }
}

let theme = createTheme({
    typography: {
        fontFamily: ['Raleway Variable', 'Helvetica Neue', 'Arial', 'sans-serif'].join(','),
    },
    palette: {
        secondary: {
            main: '#888',
        },
        poolEventHeaderPrimary: { main: '#888' },
    },
    components: {
        MuiSpeedDialAction: {
            styleOverrides: {
                staticTooltipLabel: {
                    width: 150,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
    },
    shape: {
        borderRadius: 5,
    },
})
theme = responsiveFontSizes(theme, { factor: 3 })

export type NextPageWithLayout<P = Record<string, never>, IP = P> = NextPage<P, IP> & {
    getPageLayout?: (page: ReactElement) => ReactElement<any, any> | null
}

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout
    emotionCache?: EmotionCache
}

const clientSideEmotionCache = createEmotionCache()

function MyApp({ Component, pageProps, emotionCache = clientSideEmotionCache }: AppPropsWithLayout) {
    const router = useRouter()
    const { t } = useTranslation('common')

    useEffect(() => {
        const handleRouteChange = (url: string) => {
            ga.pageview()
        }
        //When the component is mounted, subscribe to router changes
        //and log those page views
        router.events.on('routeChangeComplete', handleRouteChange)

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
        }
    }, [router.events])

    const { session } = pageProps as any

    // Use the layout defined at the page level, if available
    const getLayout = Component.getPageLayout ?? ((page) => <PageLayout {...pageProps}>{page}</PageLayout>)

    return (
        <ThemeProvider theme={theme}>
            <CacheProvider value={emotionCache}>
                <AuthProvider session={session}>
                    <ConfirmModalContextProvider>
                        <CssBaseline />
                        <CookieConsent
                            ButtonComponent={Button}
                            customButtonProps={{
                                style: {
                                    marginRight: '10px',
                                    textTransform: 'capitalize',
                                    boxShadow: 'none',
                                    fontSize: '1.1rem',
                                    '&:hover': {
                                        boxShadow: 'none',
                                    },
                                },
                                endIcon: <CloseIcon />,
                                color: 'info',
                                variant: 'contained',
                            }}
                            customDeclineButtonProps={{
                                style: {
                                    marginRight: '10px',
                                    textTransform: 'capitalize',
                                },
                                color: 'info',
                                variant: 'outlined',
                            }}
                            // disableButtonStyles
                            location="bottom"
                            buttonText={t('cookieConsentAccept')}
                            enableDeclineButton={true}
                            declineButtonText={t('cookieConsentDecline')}
                            style={{
                                padding: '10px',
                                backgroundColor: '#eaeaea',
                                borderTop: 'solid #ddd 1px',
                                color: '#000',
                                zIndex: 2000,
                            }}
                        >
                            <Trans t={t} i18nKey="cookieConsentText">
                                Ce site web et certains de nos partenaires utilisent des cookies pour
                                améliorer l'expérience de navigation. Pour plus d'information, consultez notre
                                <a href="/privacy">politique de confidentialité</a>.
                            </Trans>
                        </CookieConsent>
                        <NextNProgress color="#000" showOnShallow={false} />
                        <ToastContainer
                            theme="colored"
                            position="bottom-center"
                            autoClose={2000}
                            hideProgressBar={true}
                            draggable={false}
                            newestOnTop={true}
                        />
                        {getLayout(
                            <>
                                <ErrorBoundary
                                    fallback={
                                        <PoolLayout>
                                            <Container>{t('errorBoundary.message')}</Container>
                                        </PoolLayout>
                                    }
                                >
                                    <Component {...pageProps} />
                                </ErrorBoundary>
                                <GoogleAdSense
                                    publisherId={`${process.env.NEXT_PUBLIC_ADSENSE_PUBLISHER_ID}`}
                                />
                            </>
                        )}
                    </ConfirmModalContextProvider>
                </AuthProvider>
            </CacheProvider>
        </ThemeProvider>
    )
}

export default appWithTranslation(MyApp)
