// log the pageview with their URL
export const pageview = (url?: string) => {
    const params: any = {}
    if (url) {
        params.page_path = url
    }

    // no need to send manual event since Enhanced measurement, 
    // which is enabled by default, sends a pageview when the browser history state changes,
    // and a history stage change happen with Nextjs link component
    // https://support.google.com/analytics/answer/11403294?hl=en#zippy=%2Cglobal-site-tag-websites

    // if (process.env.NODE_ENV === 'development') {
    //     params.debug_mode = true
    // }
    // window.gtag(
    //     'event',
    //     'page_view'
    // )
}

// log specific events happening.
export const event = ({ action, params }: { action: string, params: any }) => {
    // the default config provided in the global site tag snippet is also used here,
    // so no need to specify debug_mode again

    // params = params ?? {}
    // if (process.env.NODE_ENV === 'development') {
    //     params.debug_mode = true
    // }
    window.gtag('event', action, params)
}